import React from 'react'

function TecsResponsive() {
    return (
        <div className="tecs__container_responsive">
            <h4 className="tecs__containerTitle_responsive">Front-End</h4>
            <hr className='tecs__container_line' />
            <ul className="tecs__badges_responsive">
                <img src="https://img.shields.io/badge/html5-%23E34F26.svg?style=for-the-badge&logo=html5&logoColor=white" alt="html5" />
                <img src="https://img.shields.io/badge/css3-%231572B6.svg?style=for-the-badge&logo=css3&logoColor=white" alt="css3" />
                <img src="https://img.shields.io/badge/javascript-%23323330.svg?style=for-the-badge&logo=javascript&logoColor=F7DF1E" alt="js" />
                <img src="https://img.shields.io/badge/react-%2320232a.svg?style=for-the-badge&logo=react&logoColor=61DAFB" alt="react" />
                <img src="https://img.shields.io/badge/tailwindcss-%2338B2AC.svg?style=for-the-badge&logo=tailwind-css&logoColor=white" alt="tailwindcss" />
                <img src="https://img.shields.io/badge/Next-black?style=for-the-badge&logo=next.js&logoColor=white" alt="Nextjs" />
            </ul>
            <h4 className="tecs__containerTitle_responsive">Back-End</h4>
            <hr className='tecs__container_line' />
            <ul className="tecs__badges_responsive">
                <img src="https://img.shields.io/badge/node.js-43853D.svg?style=for-the-badge&logo=node.js&logoColor=white" alt="nodejs" />
                <img src="https://img.shields.io/badge/mysql-%234EA94B.svg?style=for-the-badge&logo=mysql&logoColor=white" alt="mysql" />
                <img src="https://img.shields.io/badge/express.js-000000.svg?style=for-the-badge&logo=express&logoColor=white" alt="express" />
                <img src="https://img.shields.io/badge/mongodb-%234EA94B.svg?style=for-the-badge&logo=mongodb&logoColor=white" alt="mongodb" />
            </ul>
            <h4 className="tecs__containerTitle_responsive">Tools</h4>
            <hr className='tecs__container_line' />
            <ul className="tecs__badges_responsive">
                <img src="https://img.shields.io/badge/github-%23121011.svg?style=for-the-badge&logo=github&logoColor=white" alt="github" />
                <img src="https://img.shields.io/badge/Visual%20Studio%20Code-0078d7.svg?style=for-the-badge&logo=visual-studio-code&logoColor=white" alt="vscode" />
                <img src="https://img.shields.io/badge/notion-%23000000.svg?style=for-the-badge&logo=notion&logoColor=white" alt="notion" />
                <img src="https://img.shields.io/badge/postman-%23FF6C37.svg?style=for-the-badge&logo=postman&logoColor=white" alt="postman" />
            </ul>
        </div>
    )
}

export default TecsResponsive